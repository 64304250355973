.timelines {
	@include grid-flex();
	@include grid-flex-direction(column);

	&__item {
		@include grid-flex();
		@include grid-flex-align-items(center);
		@include grid-flex-direction(column-reverse);
		padding-bottom: 2rem;
		position: relative;

		@include mq(md) {
			@include grid-flex-direction(row);
			width: 50%;
		}

		&__content {
			position: relative;
			background-color: $white;
			border-radius: 2px;
			padding: 1.5rem 2rem;
			@include box-shadow(0 12px 47px 0 rgb(0 0 0 / 4%));

			@include mq(md) {
				margin-right: 2rem;

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 100%;
					width: 0;
					height: 0;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-left: 10px solid $white;
				}
			}
		}
		&__point {
			position: relative;
			margin-bottom: 1rem;
			z-index: 2;
			&__content {
				background-color: $white;
				border-top-left-radius: 1rem;
				border-bottom-left-radius: 1rem;
				border-top-right-radius: 1rem;
				border-bottom-right-radius: 1rem;
				color: $primary-color;
				font-weight: bold;
				padding: 0.5rem 1rem 0.5em 2rem;
				position: relative;
				@include box-shadow(0 12px 47px 0 rgb(0 0 0 / 4%));
				&:before {
					position: absolute;
					content: ' ';
					background-color: $primary-color;
					width: 1rem;
					height: 1rem;
					left: 0.5rem;
					top: 0.6rem;
					border-radius: 50%;
				}
			}

			@include mq(md) {
				margin-bottom: 0;
				margin-right: -72px;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;

				&__content {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		@include mq(md) {
			&:not(:first-child) {
				&:before {
					content: '';
					position: absolute;
					background-color: #E1E1E1;
					width: 2px;
					top: 0;
					right: -1px;
					bottom: 50%;
					z-index: 0;
				}
			}

			&:not(:last-child) {
				&:after {
					content: '';
					position: absolute;
					background-color: #E1E1E1;
					width: 2px;
					top: 50%;
					right: -1px;
					bottom: 0;
					z-index: 0;
				}
			}

			&:nth-child(even) {
				@include grid-flex-direction(row-reverse);
				align-self: flex-end;
				.timelines__item {
					&__content {
						margin-right: 0;
						margin-left: 2rem;
						&:after {
							left: auto;
							right: 100%;
							border-top: 10px solid transparent;
							border-bottom: 10px solid transparent;
							border-right: 10px solid $white;
							border-left: none;
						}
					}
					&__point {
						margin-right: 0;
						margin-left: -72px;
						&__content {
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
							border-top-right-radius: 1rem;
							border-bottom-right-radius: 1rem;
							padding: 0.5rem 2rem 0.5em 1rem;
							&:before {
								left: auto;
								right: 0.5rem;
							}
						}
					}
				}

				&:before {
					left: -1px;
					right: auto;
				}

				&:after {
					left: -1px;
					right: auto;
				}
			}
		}
	}
}
