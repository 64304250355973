table.table {
	border-collapse: collapse;
	vertical-align: middle;
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1rem;

	thead {
		tr {
			th {
				font-weight: 600;
				border-bottom: 1px solid $silver2;
				text-align: left;
				padding: 1.2em 0.8em;
				background-color: $gray;
			}
		}
	}

	tbody {
		tr {
			td {
				font-weight: 400;
				border-bottom: 1px solid $silver2;
				text-align: left;
				padding: 1.2em 0.8em;
			}
		}
	}

	tfoot {
		tr {
			td {
				font-weight: 400;
				border-bottom: 1px solid $silver2;
				text-align: left;
				padding: 1.2em 0.8em;
			}
		}
	}

	td, th {
		&.col-2 {
			width: 20%;
		}
		&.col-3 {
			width: 30%;
		}
		&.col-4 {
			width: 40%;
		}
		&.col-5 {
			width: 50%;
		}
		&.col-6 {
			width: 60%;
		}
		&.text-left {
			text-align: left;
		}
		&.text-right {
			text-align: right;
		}
		&.text-center {
			text-align: center;
		}
	}
}

table.table--bordered {
	th, td {
		border: 1px solid $silver2;
	}
}

table.table--striped {
	> tbody > tr:nth-of-type(odd) > * {
		background-color: lighten($silver2, 5);
	}
}

table.table--sm {
	tbody, thead, tfoot {
		tr {
			th, td {
				padding: 1em 0.8em;
			}
		}
	}
}

table.table--list {
	thead {
		tr {
			th, td {
				color: $link-color;
				border-bottom: 3px solid $primary-color;
			}
		}
	}
}

.table {
	th {
		.sorting {
			float: right;
			position: relative;
			margin-left: 0.5em;
			line-height: 0.3;
			top: 3px;
			a:before {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-left: 3px solid transparent;
				border-right: 3px solid transparent;
				border-top-color: #999;
				border-bottom-color: #999;
				line-height: 0;

				&.active {
					border-top-color: #f00;
					border-bottom-color: #f00;
				}
			}
			@include e(asc) {
				&:before {
					border-bottom-style: solid;
					border-bottom-width: 3px;
				}
			}
			@include e(desc) {
				&:before {
					border-top-style: solid;
					border-top-width: 3px;
				}
			}
		}
	}
}

.table-responsible-md,
.table-responsible {
	@include mqs(lg) {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		.table {
			overflow: hidden;
			table-layout: auto !important;
		}
	}
}

.table-responsible-lg {
	@include mqs(lg) {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		.table {
			overflow: hidden;
			table-layout: auto !important;
		}
	}
}

.table-responsible-mq-xs {
	@include mq(xs) {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		.table {
			overflow: hidden;
			table-layout: auto !important;
		}
	}
}

.table-responsible-mq-sm {
	@include mq(sm) {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		.table {
			overflow: hidden;
			table-layout: auto !important;
		}
	}
}

.table-responsible-mq-md {
	@include mq(md) {
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		.table {
			overflow: hidden;
			table-layout: auto !important;
		}
	}
}