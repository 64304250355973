.nav-tabs {
	@include grid-flex();
	@include grid-flex-align-items(flex-end);
	list-style: none;
	border-bottom: 1px solid $tabs-border-color;
	margin-bottom: 1rem;

	.nav-link {
		padding: 1em 0.8em;
		margin-right: -1px;
		margin-bottom: -1px;
		border: 1px solid $tabs-border-color;
		background-color: $tabs-background;
		color: $tabs-color;
		cursor: pointer;
		&.active {
			position: relative;
			background-color: $tabs-background-active;
			border-color: $tabs-border-color $tabs-border-color $tabs-background-active;

			@include psuedo-el(before, 100%, 2px) {
				background-color: $tabs-border-color-active;
				border-left: 1px solid $tabs-border-color-active;
				border-right: 1px solid $tabs-border-color-active;
				top: -2px;
				left: -1px;
			}
		}
		&:not(.active):hover {
			background-color: darken($tabs-background, 5%);
		}
	}
}

.tab-content {
	.tab-pane {
		display: none;

		&.active {
			display: block;
		}
	}
}