.sidenav {
	display: none;
	z-index: $zindex-sidebar;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($backdrop-color, .5);

	nav {
		overflow-y: auto;
		position: fixed;
		max-width: 300px;
		width: 100%;
		padding: 0;
		top: 0;
		bottom: 0;
		right: -70%;
		background-color: $primary-color;
		padding-top: 60px;

		@include transition-property(right);
		@include transition-duration(1s);
		@include transition-timing-function(ease);

		> ul {
			> li {
				display: block;
				padding: 0 30px 0 30px;

				> a {
					display: block;
					padding: 15px 0;
					color: $white;
					font-weight: 500;
				}

				.contact-box {
					color: transparentize($white, .2);
					a {
						color: $white;
						text-decoration: underline;
					}
				}

				&.bordered {
					margin: 40px 0 25px 0;

					a {
						border: 1px solid $secondary-color;
						text-align: center;
					}
				}

				&.socials {
					a {
						display: inline-block;
					}

					a + a {
						margin-left: 15px;
					}
				}

				&.separator {
					border-top: 1px solid rgba($white, .1);
				}
			}
		}

		@include mq(md) {
			padding: 2rem 0 0 0;
		}
	}

	&.in {
		display: block;
	}
}

.sidenav-opened {
	overflow: hidden;
	display: block;
}
