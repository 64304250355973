.dropdown {
	position: relative;
}

.dropdown-toggle:focus {
	outline: 0;
}

.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: $zindex-dropdown;
	display: none; // default none block .open of the menu
	min-width: 10rem;
	padding: 0.5rem 0;
	margin: 0;
	font-size: $default-font-size;
	text-align: left;
	list-style: none;
	background-color: $dropdown-background-color;
	border: 1px solid $dropdown-border-color;
	border-radius: $dropdown-border-radius;
	color: $dropdown-text-color;

	&.pull-right {
		right: 0;
		left: auto;
	}

	@include e(loading) {
		padding: 0.5rem;
		text-align: center;
	}

	@include e(item) {
		display: block;
		width: 100%;
		padding: $dropdown-item-padding-y $dropdown-item-padding-x;
		clear: both;
		white-space: nowrap;
		border: 0;
		background-color: transparent;
		font-size: $dropdown-item-font-size;
		color: $dropdown-item-text-color;
		cursor: pointer;

		@include hover() {
			background-color: $dropdown-item-background-color-hover;
		}

		@include e(title) {
			display: block;
			font-weight: bold;
		}
		@include e(subject) {
			display: block;
			margin-bottom: 0.5rem;
		}
		@include e(time) {
			display: block;
			color: $gray-400;
		}
	}

	@include e(list) {
		margin: 0;
		padding: 0;
		list-style: none;

		li + li {
			border-top: 1px solid $dropdown-border-color;
		}
	}

	@include e(actions) {
		@include e(item) {
			display: block;
			text-align: center;
			border-radius: 0;
		}
	}
}

.dropdown-menu.show {
	display: block;
}

.divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-top: 1px solid rgba(0,0,0,.15);
}
