// ==========================================================================
// $Print
// Boilerplate scss code for Printing websites.
// ==========================================================================

@media print {
  *{
    &:before,
    &:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }
  }
 body{
    font: 12pt $font-family-sans;
    line-height: 1.3;
  }
  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  // Hide all clutter
    footer{
      display: none;
    }

    header{
      margin-bottom: 40px;
      h1, nav{
        display: none;
      }
      &:after{
        content:$domain-name;
      }
      &:before{
        display: block;
        content: "Thank you for printing our content at " + $domain-name + ". Please check back soon for new contents.";
        margin-bottom: 10px;
        border: 1px solid #bbb;
        padding: 6px 8px;
        font-style: italic;
      }
    }
  // Page Margin
    @page {
      margin: 0.5cm;
    }

    h1 {
      font-size: 24pt;
    }

    h2 {
      font-size: 14pt;
      margin-top: 25px;
    }

    aside{
      border-top: 1px solid #bbb;
      margin-top: 30px;
      display: block;
      h2 {
        font-size: 18pt;
      }
    }

 // Don't show links that are fragment identifiers,
 // or use the `javascript:` pseudo protocol

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
  }
 // Printing Tables:
 // http://css-discuss.incutio.com/wiki/Printing_Tables
  thead { display: table-header-group; }
  tr,
  img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
