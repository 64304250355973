@mixin alert-variant($color) {
	background-color: $color;
	border-color: darken($color, 5%);
	color: lighten($color, 40%);

	hr {
		border-top-color: darken($color, 5%);
	}
	.alert-link {
		color: lighten($color, 40%);
	}
}