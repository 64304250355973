ul.list-outside {
	list-style-position: outside;
	padding: 0 15px;
}

ul.list-unstyle {
	list-style: none;
}

ul.list-inline {
	list-style: none;
	margin: 0;
	@include grid-flex();

	li {
		margin: 0;
	}
}

.list-tree {
	list-style: none;

	ul {
		margin: 0 0 0 20px;

		li {
			&:before {
				font-weight: 400;
				vertical-align: middle;
				content: '\e5da'
			}
		}
	}
}
