.accordion {
	&__item {
		@include box-shadow(0 12px 47px 0 rgba(0, 0, 0, 4%));
		margin-bottom: 1rem;

		&__header {
			margin-bottom: 0;
		}

		&__button {
			@include grid-flex();
			@include grid-flex-align-items(center);
			@include transition(color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease);
			width: 100%;
			padding: 1.5rem 2rem;
			position: relative;
			font-size: 1rem;
			font-weight: bold;
			text-align: left;
			color: $text-color;
			background-color: $third-color;
			border: 0;
			border-radius: 0;
			overflow-anchor: none;
			cursor: pointer !important;

			&:not(.collapsed) {
				color: $primary-color;
				background-color: $white;
				&:after {
					transform: rotate(90deg);
				}
			}

			&:after {
				@extend .link-arrow;
				transform: rotate(-90deg);
			}
		}

		&__collapse {

		}

		&__body {
			padding: 1.5rem 3rem;
		}
	}
}
