// ==========================================================================
// Helpers
// Helpers classes such as object classes used for floating an element left and right,
// text alignments etc. are included here.
// Simple helper classes are generally referred using class selectors and their css properties
// will have an !important property attached to it, so that a helper class cannot be overwritten.
// Placeholders can also be included in the helper partial for these purposes.
// ==========================================================================

.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
.align-items-center {
	align-items: center;
}
.img-fluid {
	max-width:100%;height:auto
}
%clearfix-none{
	display: inherit;
}
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
%pseudos {
	display: block;
	content: " ";
	position: absolute;
}
%shadow{
	-webkit-box-shadow: 0 8px 12px -6px transparentize($black, 0.8);
	-moz-box-shadow: 0 8px 12px -6px transparentize($black, 0.8);
	box-shadow: 0 8px 12px -6px transparentize($black, 0.8);
}
%ellipsis{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
%appearance-none {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}
%vertical-middle {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
%vertical-bottom {
	position: absolute;
	top: 80%;
	transform: translateY(-80%);
}