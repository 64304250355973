// ==========================================================================
// Base
// A Base rule is applied to HTML element using an element selector, a
// descendent selector, or a child selector, along with any pseudo-classes.
// We cannot reference any element defined here by their respective class or ID name.
// This defines the default styling for how that element should look.
// Changes in style other than default should be overwritten using other partials.
// ==========================================================================

// Reset CSS
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// Global Clearfixing
	@if $grid-type == 'float' {
		&:after {
			clear: both;
			content: "";
			display: block;
		}
	}
}

html {
	scroll-behavior: smooth;
}

// Base Code
html, body {
	font-size: $default-font-size;
}

body {
	-webkit-font-smoothing: antialiased;
	color: $text-color;
	font-size: $default-font-size;
	font-family: $font-family--primary;
	text-rendering: optimizeLegibility;
	background-color: $body;
}

a {
	outline: none;
	color: $link-color;
	text-decoration: none;
	transition: color .2s;
}

p {
	font-size: $default-font-size;
	line-height: $base__line;
	margin-bottom: 10px;
}

a, span, strong {
	&:after {
		@extend %clearfix-none;
	}
}

ul, ol {
	list-style-position: inside;
	margin-bottom: 20px;
}

h1,h2,h3,h4,h5,h6{
	font-family: $font-family-heading;
	line-height: $head-line-height;
	margin: 0;
	margin-bottom: 0.7rem;
	color: $heading-color;
	font-weight: $head-font-weight;
	small {
		display: block;
		font-weight: 900;
		text-transform: uppercase;
		font-size: $lg-font-size;
	}
}

@include mq(md) {
	h1, .h1 {
		font-size: $head1-font-size - 0.5rem;
	}
	h2, .h2 {
		font-size: $head2-font-size - 0.5rem;
	}
	h3, .h3 {
		font-size: $head3-font-size - 0.5rem;
	}
	h4, .h4 {
		font-size: $head4-font-size - 0.5rem;
	}
	h5, .h5 {
		font-size: $head5-font-size - 0.5rem;
	}
}
@include mq(lg) {
	h1, .h1 {
		font-size: $head1-font-size;
	}
	h2, .h2 {
		font-size: $head2-font-size;
	}
	h3, .h3 {
		font-size: $head3-font-size;
	}
	h4, .h4 {
		font-size: $head4-font-size;
	}
	h5, .h5 {
		font-size: $head5-font-size;
	}
}

img{
	max-width: 100%;
	vertical-align: top;
}

button,
input,
optgroup,
select,
textarea {
	font-family: $font-family-sans;
}

fieldset {
	border: 0;
	padding: 0;
	margin: 0;

	legend {
		display: block;
		width: 100%;
		padding-bottom: 0.5rem;
		margin-bottom: 1rem;
		color: $heading-color;
		font-size: $head3-font-size;
		border-bottom: 1px solid $tabs-border-color;
	}
}
