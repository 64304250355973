.page {
	&__header {
		@include grid-flex;
		@include grid-flex-justify-content(center);
		@include grid-flex-align-items(center);
		height: 350px;
		padding-top: 2rem;
		position: relative;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		@include psuedo-el(before, 100%, 100%) {
			z-index: 0;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			content: '';
			background-color: transparentize($primary-color, 0.5);
		}

		h1,h2,h3,h4,h5 {
			position: relative;
			text-align: center;
			z-index: 1;
			color: $white;
		}
	}

	&__content {
		padding: 2rem 0;

		&__inner {
			@include mq(md) {
				padding: 0 5rem;
			}
			margin-bottom: 2rem;
		}
	}
}
