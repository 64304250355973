.i-cookie {
	z-index: 3000;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	@include transition(max-height 1s ease);
	overflow: hidden;
	max-height: 0;
	background-color: $primary-color;
	color: $white;
	&__inner {
		padding: 30px 0;

		p {
			margin: 0;
			padding: 0;

			a {
				color: $white;
				text-decoration: underline;
			}
		}

		@include mqs(sm) {
			p {
				margin-bottom: 30px;
			}
		}
	}
}
