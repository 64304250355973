@mixin form-control-focus($color: $input-border-focus) {
	$color-rgba: rgba(red($color), green($color), blue($color), .6);
	&:focus,&.focus {
		border-color: $color;
		outline: 0;
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
	}
}

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
	// Color the label and help text
	.help-block,
	.control-label,
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline,
	label,
	&.radio label,
	&.checkbox label,
	&.radio-inline label,
	&.form-control-checkbox,
	&.form-control-radio,
	&.checkbox-inline label  {
		color: $text-color;
	}

	.form-checkbox label,
	.form-switch label,
	.form-radio label {
		border-color: $text-color;
	}

	// Set the border and box shadow on specific inputs to match
	.form-control {
		border-color: $border-color;
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
		&:focus {
			border-color: darken($border-color, 10%);
			$shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
			@include box-shadow($shadow);
		}
	}
	// Set validation states also for addons
	.input-group-addon {
		color: $text-color;
		border-color: $border-color;
		//background-color: $background-color;
	}
	// Optional feedback icon
	.form-control-feedback {
		color: $text-color;
	}
}
@mixin input-size($input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
	height: $input-height;
	padding: $padding-vertical $padding-horizontal;
	font-size: $font-size;
	line-height: $line-height;
	border-radius: $border-radius;

	select & {
		height: $input-height;
		line-height: $input-height;
	}

	textarea &,
	select[multiple] & {
		height: auto;
	}
}