.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-italic         { font-style: italic; }
.text-nowrap         { white-space: nowrap; }
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }
.text-bold           { font-weight: bold !important; }
.text-normal         { font-weight: normal !important; }
.text-size30         { font-size: rem(30px); }
.text-size25         { font-size: rem(25px); }
.text-size20         { font-size: rem(20px); }
.text-size16         { font-size: rem(16px); }
.text-size13         { font-size: rem(13px); }
.text-small          { font-size: $small-font-size; }
.text-white          { color: $white !important; }
.text-primary        { color: $primary-color !important; }
.text-secondary      { color: $secondary-color !important; }
.text-success        { color: $success !important;}
.text-black          { color: $text-color !important; }
.text-error          { color: $error !important; }
.text-gray           { color: $gray !important; }
.text-gray-dark      { color: $silver3 !important; }
.text-warning        { color: $warning !important; }
.text-underline      { text-decoration: underline; }
.text-link-deco      { a{text-decoration: underline;} }
.text-more-text      { display: none; }
.text-block          { display: block; }
.text-icon           { height: 14px; vertical-align: middle; }
.text-third          { font-family: $font-family--third; }
.text-column-2 {
	@include mq(lg) {
		@include content-columns(2);
	}
}
.text-column-3 {
	@include mq(lg) {
		@include content-columns(3);
	}
}

h1,h2,h3,h4,h5,h6{
	small {
		color: $secondary-color;

		.theme--mitsubishi & {
			color: $mitsubishi-color;
		}
		.theme--luve & {
			color: $luve-color;
		}
		.theme--hisense & {
			color: $hisense-color;
		}
		.theme--kelvion & {
			color: $kelvion-color;
		}
		.theme--thermofin & {
			color: $thermofin-color;
		}
		.theme--uniflair & {
			color: $uniflair-color;
		}
	}
}
