@use "sass:math";

// Override content-box in Normalize (* isn't specific enough)
input[type="search"] {
	box-sizing: border-box;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
	// margin: 4px 0 0;
	// margin-top: 1px \9; // IE8-9
	// line-height: normal;
	display: none;
}

input[type="file"] {
	display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
	display: block;
	width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
	height: auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

label {
	display: inline-block;
	max-width: 100%; // (see https://github.com/twbs/bootstrap/issues/13141)
	margin-bottom: 5px;
	font-weight: 400;
	font-size: $default-font-size;
	small {
		font-size: $default-font-size;
		color: $input-placeholder-color;
	}
	a {
		text-decoration: underline;
	}
}

.form-control-line {
	border: 0;
	border-bottom: 1px solid $primary-color;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	position: relative;
	padding: 6px 6px 6px 20px;

	@include psuedo-el(after, 12px, 11px) {
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwIDYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgtMC43MDcxMDcsLTAuNzA3MTA3LDAuNzA3MTA3LC0wLjcwNzEwNyw3NzkuODMzLDkzNi4zNjUpIj4KICAgICAgICA8ZyBpZD0iR3JvdXBfOTMxIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwXzkzMCI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iUmVjdGFuZ2xlXzc0IiB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLDEyMDYsMTEwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTEuMzcsMC42ODVDMS4zNywwLjMwNyAxLjA2MywwIDAuNjg1LDBDMC4zMDcsMCAwLDAuMzA3IDAsMC42ODVMMCw2LjE2NUMwLDYuNTQzIDAuMzA3LDYuODUgMC42ODUsNi44NUMxLjA2Myw2Ljg1IDEuMzcsNi41NDMgMS4zNyw2LjE2NUwxLjM3LDAuNjg1WiIgc3R5bGU9ImZpbGw6cmdiKDI2LDQxLDYyKTsiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDxnIGlkPSJSZWN0YW5nbGVfNzUiIHRyYW5zZm9ybT0ibWF0cml4KC0zLjgyODU3ZS0xNiwxLC0xLC0zLjgyODU3ZS0xNiwxMjEyLjg1LDExMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjM3LDAuNjg1QzEuMzcsMC4zMDcgMS4wNjMsMCAwLjY4NSwwQzAuMzA3LDAgMCwwLjMwNyAwLDAuNjg1TDAsNi4xNjVDMCw2LjU0MyAwLjMwNyw2Ljg1IDAuNjg1LDYuODVDMS4wNjMsNi44NSAxLjM3LDYuNTQzIDEuMzcsNi4xNjVMMS4zNywwLjY4NVoiIHN0eWxlPSJmaWxsOnJnYigyNiw0MSw2Mik7Ii8+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=);
		background-position: center center;
		background-size: 100% 100%;
		left: 0;
		top: 10px;
		background-repeat: no-repeat;
		background-color: transparent;
		@include transition-duration(.4s);
		@include transition-property(transform);
	}

	@include m(without) {
		border-bottom: 0;
	}

	&.opened:after {
		@include rotate(180deg);
	}
	&[data-toggle="collapse"]:not(.collapsed):after {
		@include rotate(180deg);
	}
}

.form-text {
	color: $input-text-color;
	font-size: $input-text-size;
	margin-top: 0.5rem;
}

.form-placeholder {
	color: $input-placeholder-color;
}

.input-group {
	@include grid-flex();
}

.input-group-addon {
	@include grid-flex();
	@include grid-flex-align-items(center);
	border: 1px solid $input-border-color;
	padding: $input-padding-vertical $input-padding-horizontal;
	white-space: nowrap;
	background-color: $input-group-addon-bg;

	&:first-child {
		border-right: 0;
	}

	&:last-child {
		border-left: 0;
	}

	&:not(:first-child):not(:last-child) {
		border-left: 0;
		border-right: 0;
	}
}

.form-control {
	display: block;
	width: 100%;
	padding: $input-padding-vertical $input-padding-horizontal;
	font-size: $default-font-size;
	line-height: $base__line;
	color: $input-color;
	height: $input-height;
	outline: none;
	background-color: $input-background;
	background-image: none;
	border: 1px solid $input-border-color;
	border-radius: 0;
	@include box-shadow(0 4px 7px 0 rgba(0, 0, 0, 4%));
	@include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
	@include placeholder($input-placeholder-color);
	@include form-control-focus($input-border-focus);
	&::-ms-expand {
		border: 0;
		background-color: transparent;
	}
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $input-background-disabled;
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}

	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}

	@include m(large) {
		max-width: 350px;
	}
	@include m(medium) {
		max-width: 170px;
	}
	@include m(small) {
		max-width: 100px;
	}
	@include m(noborder) {
		border: 0;
		padding: 0 15px;
	}
	@include m(auto) {
		height: auto;
	}
	@include m(inline) {
		display: inline-block;
		width: auto;
		height: 30px;
	}
}

select.form-control {
	@extend %appearance-none;
	padding-right: $form-select-padding-right;
	background-repeat: no-repeat;
	background-image: escape-svg($form-select-indicator);
	background-position: $form-select-bg-position, $form-select-feedback-icon-position;
	background-size: $form-select-bg-size, $form-select-feedback-icon-size;
}

textarea.form-control {
	height: auto;
}

.form-additional {
	color: $silver2;
}

.form-control-description {
	color: $silver3;
	margin: 0;
	padding: 0;
}

%checkboxRadioActiveBorderColor {
	border-color: $primary-color;
}

.form-phone {
	@include grid-flex();
	@include e(prefix) {
		flex: 0 0 120px;
		width: 120px;

		.ts-control > input {
			min-width: 3rem;
		}
	}
	@include e(number) {
		@include flex(1);
	}
}

.form-radio {
	display: inline-block;
	@include e(label) {
		width: math.ceil(calc($input-height/1.5));
		height: math.ceil(calc($input-height/1.5));
		border: 1px solid $input-border-color;
		vertical-align: middle;
		display: inline-block;
		margin: 2px 0.25rem 2px 0;
		cursor: pointer;
		@include form-control-focus($input-border-focus);
	}

	input[type="radio"] ~ label.form-radio__label {
		border-radius: 50%;
		background-color: $input-background;
		position: relative;
	}

	input[type="radio"]:checked ~ label.form-radio__label {
		@extend %checkboxRadioActiveBorderColor;

		@include psuedo-el('before', math.ceil(calc($input-height/1.5))-6, math.ceil(calc($input-height/1.5))-6) {
			background-color: $primary-color;
			border-radius: 50%;
			top: 2px;
			left: 2px;
		}
	}

	input[type="radio"]:disabled ~ label.form-radio__label {
		background-color: $input-background-disabled;
	}
}

.form-switch {
	display: inline-block;
	@include e(label) {
		width: calc($input-height * 2);
		height: $input-height;
		border: 1px solid $input-border-color;
		vertical-align: middle;
		display: inline-block;
		cursor: pointer;
		position: relative;
		@include form-control-focus($input-border-focus);
	}

	input[type="checkbox"] ~ label.form-switch__label {
		@include psuedo-el('before', $input-height - 6, $input-height - 6) {
			@include transition(transform .5s ease);
			background-color: $input-border-color;
			top: 2px;
			left: 2px;
		}
	}

	input[type="checkbox"]:checked ~ label.form-switch__label {
		border: 1px solid $primary-color;
	}

	input[type="checkbox"]:checked ~ label.form-switch__label:before {
		transform: translateX($input-height);
		background-color: $primary-color;
	}
}

.form-checkbox {
	display: inline-block;
	@include e(label) {
		width: $input-height;
		height: $input-height;
		border: 1px solid $input-border-color;
		vertical-align: middle;
		display: inline-block;
		//margin: 2px 0.25rem 2px 0;
		margin: 0 0.25rem 2px 0;
		cursor: pointer;
		@include form-control-focus($input-border-focus);
	}

	input[type="checkbox"] ~ label.form-checkbox__label {
		border-radius: $input-radius;
		background-color: $input-background;
		background-image: url('../images/check-default.svg');
		background-size: calc($input-height / 2);
		background-repeat: no-repeat;
		background-position: center center;
	}

	input[type="checkbox"]:checked ~ label.form-checkbox__label {
		@extend %checkboxRadioActiveBorderColor;
		background-image: url('../images/check-white.svg');
		background-color: $primary-color;
	}

	input[type="checkbox"]:disabled ~ label.form-checkbox__label {
		background-color: $input-background-disabled;
		background-image: url('../images/check-default.svg');
	}
}

.form-uploader {
	height: auto;
	min-height: $input-height;

	input {
		display: none;
	}

	@include m(disabled) {
		background-color: $input-background-disabled;
		opacity: 1;
		cursor: not-allowed;
	}

	@include m(dragging) {
		border-color: $input-border-focus;
		outline: 0;
	}

	@include m(success) {
		border-color: $success;

		.form-uploader__result {
			background-color: $success;
			color: $white;
		}
	}

	@include m(failed) {
		border-color: $error;

		.form-uploader__result {
			background-color: $error;
			color: $white;
		}
	}

	@include e(result) {
		text-align: center;
		background-color: $silver2;
	}

	@include e(file) {
		@include grid-flex();

		@include e(name) {

		}

		@include e(size) {
			margin-left: auto;
		}

		@include m(success) {
			color: $success;
		}

		@include m(failed) {
			color: $error
		}
	}

	@include e(empty) {
		color: $input-placeholder-color;
	}

	@include e(reset) {

	}
}

.form-control-price	{
	padding: 30px 0;
	overflow: visible;
	position: relative;
	@include e(value) {
		position: relative;
		@include rem($default-font-size - 1);
		@include m(left) {
			position: absolute;
			left: 0;
		}
		@include m(right) {
			position: absolute;
			right: 0;
		}
	}
}

input[type="search"] {
	-webkit-appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
	&[disabled],
	&.disabled,
	fieldset[disabled] & {
		cursor: not-allowed;
	}
}

.help-block {
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	color: $silver3;
}

.has-success {
	@include form-control-validation($success, $success, $success);
}
.has-warning {
	@include form-control-validation($warning, $warning, $warning);
}
.has-error {
	@include form-control-validation($error, $error, $error);
}
.form-group {
	@extend %clearfix;
	margin-bottom: $form-group-margin;
	@include mq(sm) {
		&--inline {
			@include grid-flex();
			gap: 0.5rem;

			> .form-group {
				margin-bottom: 0;
			}

			> .form-control, .form-control-checkbox, .form-control-radio {
				display: inline-block;
				width: auto; // Prevent labels from stacking above inputs in `.form-group`
				vertical-align: middle;
			}
		}
	}
}

.form-code-generator {
	@include e(button) {
		cursor: pointer !important;
	}
}

.form-select-item {
	text-shadow: none !important;
	color: $text-color !important;
	@include grid-flex-align-items(center);

	@include m(product) {
		width: 100%;
		background-color: transparent !important;
		border: 1px solid $input-border-color !important;

		&.active {
			border: 1px solid $primary-color !important;
		}

		&.im-autocomplete__item:hover {
			border: 1px solid $primary-color !important;
		}
	}

	@include e(name) {
		font-weight: 600;
	}

	@include e(description) {
		font-size: $small-font-size;
	}
}
