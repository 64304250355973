.product-item {
	color: $text-color;

	&__inner {
		position: relative;
		&::after {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: "";
			pointer-events: none;
			background-color: rgba(0, 0, 0, .03);
		}
	}

	&__tags {
		position: absolute;
		top: 1rem;
		left: 1rem;
	}

	&__content {
		color: inherit;
		padding: 1rem 1rem;
		font-size: $default-font-size;

		h1,h2,h3,h4,h5 {
			color: inherit;
			font-size: $head4-font-size;
			margin-bottom: 0;
		}

		p {
			color: #5B5B5B;
			margin-bottom: 0;
		}
	}
}

.products {
	&__item {
		margin-bottom: 2rem;
	}
}

.product-tag {
	display: inline-block;
	font-weight: bold;
	text-align: center;
	padding: 0.5rem 1rem;
	background-color: $silver2;
	border-radius: 2px;
	font-size: $small-font-size;

	&--primary {
		background-color: $primary-color;
		color: $white;
	}

	&--secondary {
		background-color: $secondary-color;
		color: $white;
	}

	&--third {
		background-color: $third-color;
		color: $text-color;
	}
}

.products-wrapper {
	&__items {
		margin-bottom: 4rem;
	}
	&__pagination {
		text-align: center;
	}
}

.parameters-list {
	list-style: none;
	@include grid-flex();
	@include grid-flex-wrap(wrap);
	@include grid-flex-direction(column);

	@include mq(md) {
		@include grid-flex-direction(row);
		li {
			width: 50%;
		}
	}
}
