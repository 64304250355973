.modal-open {
	overflow: hidden;
}

// Container that the modal scrolls within
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3501;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;

	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;

	// When fading in the modal, animate it to slide down
	&.fade .modal-dialog {
		@include translate(0, -25%);
		@include transition-transform(0.3s ease-out);
	}
	&.in .modal-dialog { @include translate(0, 0); }
	&.show .modal-dialog { @include translate(0, 0); }
}
.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
	@include box-shadow(0 0 20px rgba(0, 0, 0, .1));

	@include mq(sm) {
		width: 100%;
		max-width: 600px;
		margin: 90px auto;

		@include m(sm) {
			max-width: 300px;
		}
		@include m(lg) {
			max-width: 900px;
		}
	}

	@include m(border) {
		border-top: 3px solid $secondary-color;
	}
}

.modal-header {
	padding: 0 0 20px;

	.btn-close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: $error;
		font-size: $head3-font-size;
	}
}

.modal-content {
	padding: 40px;
	@include m(full-view) {
		padding: 0;

		.modal-body {
			padding: 0;

			.close {
				position: absolute;
				top: 15px;
				right: 15px;
			}
		}
	}
}

.modal-body {
	padding: 20px 0;
}

.modal-footer {
	padding-top: 20px;
}

.modal-title {
	@include rem($head2-font-size);
}

.modal-dialog {
	position: relative;
	background-color: $white;
	background-clip: padding-box;
	border-radius: 3px;
	outline: 0;
}

// Modal background
.modal-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 3500;
	background-color: $backdrop-color;
	// Fade for backdrop
	&.in { @include opacity(0.8); }
}
