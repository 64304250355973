.pagination {
	@include grid-flex-inline();
	list-style: none;
	padding: 0;
	margin: 10px 0;
	border: 1px solid #dedede;
	> li {
		display: inline-block;
		> a, span {
			display: block;
			text-align: center;
			padding: 10px;
			font-size: $lg-font-size;
			background-color: #fff;

			@include hover() {
				background-color: transparentize($primary-color, .9);
			}
		}

		&.active {
			font-weight: 500;

			> a, span {
				background-color: $primary-color;
				color: #fff;

				@include hover() {
					background-color: $primary-color;
				}
			}
		}
	}

	> li + li a, > li + li span {
		border-left: 1px solid #dedede;
	}

	.icons {
		vertical-align: middle;
	}
}
