@mixin button-variant($color, $fontColor: $white) {
	background-color: $color;
	border-color: $color;
	color: $fontColor;

	@include hover() {
		background-color: darken($color, 5%);
	}
}

@mixin button-outline-variant($color, $color-focus: $white) {
	background-color: transparent;
	border-color: $color;
	color: $color;

	@include hover() {
		background-color: $color;
		color: $color-focus;
	}
}

@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
	padding: $padding-y $padding-x;
	font-size: $font-size;
	border-radius: $border-radius;
}
