@include margin-classes();

.navigator-top {
	display: none;
	background-color: $primary-color;
	width: 40px;
	z-index: 1000;
	height: 40px;
	text-align: center;
	line-height: 35px;
	border-radius: 50%;
	position: fixed;
	right: 1rem;
	bottom: 2rem;

	img {
		vertical-align: middle;
	}
}

.icon-boxes {
	@include grid-flex();
	list-style: none;
	margin: 0;
}

.icon-box {
	@include grid-flex-inline();
	@include grid-flex-align-items(center);
	@include grid-flex-justify-content(center);
	width: 40px;
	height: 40px;
	border-radius: 5px;
	background-color: $primary-color;

	svg {
		fill: $white;
		stroke: $white;
	}
}

.search-box {
	position: relative;
	display: block;
	width: 100%;

	&__btn {
		@include grid-flex();
		@include grid-flex-align-items(center);
		@include grid-flex-justify-content(center);
		padding: 0 0.5rem;
		background-color: transparent;
		border: 0;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
	}
	&__control {

	}
}

.box {
	&--primary {
		background-color: $primary-color;
	}

	&--secondary {
		background-color: $secondary-color;
	}

	&--grey {
		background-color: $silver2;
	}

	&__inner {
		@include grid-flex();
		@include grid-flex-direction(column);

		@include mq(md) {
			@include grid-flex-direction(row);
		}
	}

	@include mq(md) {
		&--half {
			.box__image {
				width: 50%;
				flex: 0 0 50%;
			}

			.box__content {
				width: 50%;
				flex: 0 0 50%;
			}
		}
	}

	&__link {
		position: absolute;
		top: 2rem;
		left: 2rem;
		color: $text-color;
		font-weight: 500;

		.link-arrow {
			vertical-align: middle;
			margin-right: 1rem;
		}
	}

	&__image {
		min-height: auto;
		max-width: 100%;
		position: relative;

		img, video {
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include mq(md) {
				position: absolute;
			}
		}

		&--fit {
			img, video {
				position: relative !important;
				object-fit: unset;
				width: auto;
				height: auto;
			}
		}

		&--center {
			@include grid-flex();
			@include grid-flex-align-items(center);
			@include grid-flex-align-content(center);
			@include grid-flex-justify-content(center);
		}
	}

	&__content {
		@include grid-flex();
		@include grid-flex-direction(column);
	}
}

.box-content {
	&--container {
		.box-content__inner {
			@include make-container-half();
			float: right;
		}

		&--right {
			.box-content__inner {
				float: left;
			}
		}
	}

	&__inner {
		padding: 2rem;

		ul {
			list-style: none;

			li {
				position: relative;
				margin-bottom: 1rem;
				padding-left: 2rem;

				&::before {
					font-size: $small-font-size;
					content: '';
					position: absolute;
					left: 0;
					top: -2px;
					width: 25px;
					height: 25px;
					border-radius: 50%;
					background-color: $primary-color;
					//background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$white}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
					background-image: escape-svg(url("data:image/svg+xml,<svg width='100%' height='100%' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'><g transform='matrix(1,0,0,1,-7486.31,-666.683)'><path fill='#{$white}' d='M7490.38,676.678C7490.12,676.41 7490.12,675.977 7490.38,675.709L7499.21,666.884C7499.48,666.616 7499.91,666.616 7500.18,666.884C7500.44,667.152 7500.44,667.585 7500.18,667.853L7491.35,676.678C7491.09,676.945 7490.65,676.945 7490.38,676.678Z'/><path fill='#{$white}' d='M7486.51,671.834C7486.78,671.566 7487.21,671.566 7487.48,671.834L7491.35,675.709C7491.62,675.977 7491.62,676.41 7491.35,676.678C7491.09,676.946 7490.65,676.946 7490.38,676.678L7486.51,672.803C7486.24,672.535 7486.24,672.102 7486.51,671.834Z'/></g></svg>"));
					background-repeat: no-repeat;
					background-size: 15px 15px;
					background-position: center center;
				}
			}
		}
	}

	&--primary {
		background-color: $primary-color;
		color: $white;

		h1,h2,h3,h4,h5 {
			color: $white;
		}

		a {
			color: $white;
			text-decoration: underline;
		}

		ul {
			li {
				&:before {
					background-color: $secondary-color;
				}
			}
		}
	}

	&--secondary {
		background-color: $secondary-color;
		color: $white;

		h1, h2, h3, h4, h5 {
			color: $white;
		}

		a {
			color: $white;
			text-decoration: underline;
		}

		ul {
			li {
				&:before {
					background-color: $primary-color;
				}
			}
		}
	}

	&--grey {
		background-color: $silver2;
		color: $text-color;

		h1,h2,h3,h4,h5 {
			color: $text-color;
		}

		a {
			color: $text-color;
			text-decoration: underline;
		}

		ul {
			li {
				&:before {
					background-color: $primary-color;
				}
			}
		}
	}

	&--white {
		background-color: $white;
		color: $text-color;

		h1,h2,h3,h4,h5 {
			color: $text-color;
		}

		a {
			color: $text-color;
			text-decoration: underline;
		}

		ul {
			li {
				&:before {
					background-color: $primary-color;
				}
			}
		}
	}

	&--theme {
		background-color: $primary-color;
		color: $white;

		h1,h2,h3,h4,h5 {
			color: $white;
		}

		a {
			color: $white;
			text-decoration: underline;
		}

		ul {
			li {
				&:before {
					background-color: $primary-color;
				}
			}
		}

		.theme--mitsubishi & {
			background-color: $mitsubishi-color;
		}


		.theme--luve & {
			background-color: $luve-color;
		}

		.theme--hisense & {
			background-color: $hisense-color;
		}

		.theme--kelvion & {
			background-color: $kelvion-color;
			color: $text-color;

			h1,h2,h3,h4,h5 {
				color: $text-color;
			}

			a {
				color: $text-color;
				text-decoration: underline;
			}
		}

		.theme--thermofin & {
			background-color: $thermofin-color;
		}

		.theme--uniflair & {
			background-color: $uniflair-color;
		}
	}
}

.link-arrow {
	display: inline-block;
	@include transition(transform 0.2s ease-in-out);
	@include box-shadow(0 4px 7px 0 rgba(0, 0, 0, 4%));
	flex-shrink: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-left: auto;
	content: "";
	background-color: $white;
	background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$primary-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	background-repeat: no-repeat;
	background-size: 15px 15px;
	background-position: center center;

	&--right {
		transform: rotate(180deg);
	}

	&--text-color {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$text-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--mitsubishi & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$mitsubishi-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--luve & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$luve-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--hisense & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$hisense-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--kelvion & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$kelvion-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--thermofin & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$thermofin-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}

	.theme--uniflair & {
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='#{$uniflair-color}' d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/></svg>"));
	}
}

.contact-box {
	margin-bottom: 2rem;
	h1,h2,h3,h4,h5 {
		color: $secondary-color;
		margin-bottom: 3rem;
	}

	&__image {
		@include image-absolute(350px);
	}
}

.mini-boxes {
	@include grid-flex();
	@include grid-flex-direction(column);

	@include mq(md) {
		@include grid-flex-direction(row);

		&__item {
			width: 50%;
		}
	}

	&__item {
		@include grid-flex();
		@include grid-flex-align-content(center);
		background-color: #C7C7C7;
		color: $text-color;

		h1,h2,h3,h4,h5 {
			color: $text-color;
		}

		&--primary {
			background-color: $primary-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}

		&--secondary {
			background-color: $secondary-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}

		&--grey {
			background-color: $silver3;
			color: $text-color;

			h1,h2,h3,h4,h5 {
				color: $text-color;
			}
		}

		&--link {
			.mini-boxes__item__inner {
				@include grid-flex();

				.mini-boxes__item__arrow {
					@include grid-flex();
					@include grid-flex-align-items(center);
				}
			}
		}

		&__inner {
			padding: 2rem 3rem;
		}
	}

	.theme--aermec & {
		.mini-boxes__item--theme {
			background-color: $aermec-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
	.theme--mitsubishi & {
		.mini-boxes__item--theme {
			background-color: $mitsubishi-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
	.theme--luve & {
		.mini-boxes__item--theme {
			background-color: $luve-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
	.theme--hisense & {
		.mini-boxes__item--theme {
			background-color: $hisense-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
	.theme--kelvion & {
		.mini-boxes__item--theme {
			background-color: $kelvion-color;
			color: $text-color;

			h1,h2,h3,h4,h5 {
				color: $text-color;
			}
		}
	}
	.theme--thermofin & {
		.mini-boxes__item--theme {
			background-color: $thermofin-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
	.theme--uniflair & {
		.mini-boxes__item--theme {
			background-color: $uniflair-color;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}
}

.theme--aermec {

}



.promo-icon {
	margin-bottom: 3rem;
	&__icon {
		margin-bottom: 2rem;
	}
	&__desc {

	}
}
