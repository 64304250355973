// ==========================================================================
// Config
// Define variables, maps and fonts at this partial.
// ==========================================================================

// Enter your Web Address here for print media
$domain-name: "www.penzion-zvonicka.cz";
$prefix: im- !default;

// Text
$base__font-size: 1rem;
$base__line: 1.8;

$typefaces: (
	'../fonts/Inter-Medium' : (
		family: 'Inter',
		weight: 500,
		style: normal
	),
	'../fonts/Inter-Bold' : (
		family: 'Inter',
		weight: 600,
		style: normal
	),
	'../fonts/Inter-SemiBold' : (
		family: 'Inter',
		weight: 700,
		style: normal
	),
	'../fonts/Inter-ExtraBold' : (
		family: 'Inter',
		weight: 800,
		style: normal
	),
);

$font-family-sans-fallback: 'Arial', sans-serif;
$font-family-sans: 'Inter', $font-family-sans-fallback;
$font-family-serif: 'Inter', 'Arial', serif;
$font-family-heading: 'Inter', $font-family-sans;
$font-family-monospace: 'Inconsolata', 'Menlo', 'Consolas', 'Bitstream Vera Sans Mono', 'Courier', monospace;
$font-family--primary : $font-family-sans;
$font-family--secondary : $font-family-serif;
$font-family--third: 'DancingScript', 'Arial', sans-serif;

// Colors
$white: #FFFFFF;
$black: #000000;
$gray: rgba(16, 16, 16, 0.04);
$silver: #F6F5F5;
$silver2: #EDEDED;
$silver3: #E2E2E2;
$desk: #F7F7F8;
$body: #FFFFFF;

$red: #D24D57;
$yellow: #F7CA18;
$green: #36D7B7;
$blue: #2385F9;
$dark-blue: #3E5A84;
$light-blue: #8FC6ED;

// Message Colors
$error: lighten($red,5%) !default;
$success: $green !default;
$warning: $yellow !default;
$info: $blue !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: (
	"100": $gray-100,
	"200": $gray-200,
	"300": $gray-300,
	"400": $gray-400,
	"500": $gray-500,
	"600": $gray-600,
	"700": $gray-700,
	"800": $gray-800,
	"900": $gray-900
) !default;

// Breakpoints
$sm-device: 576px;//576px;
$md-device: 768px;//768px;
$lg-device: 992px;//992px;
$xl-device: 1200px;//1200px;

// Color Palette
$palettes: (
	gray-shades: (
		base: $gray,
		dark: darken($gray,20%),
		light: lighten($gray,20%)
	),
	blue-shades: (
		base: $blue,
		dark: darken($blue,20%),
		light: lighten($blue,20%)
	)
);

// Font size variables
$default-font-size: $base__font-size;
$small-font-size: $default-font-size - 0.1;
$lg-font-size: $default-font-size + 0.1;
$default-line-height: $base__line;

// Color Usage
$primary-color: #6FC223;
$secondary-color: #1981CD;
$third-color: #F2F8FC;
$mitsubishi-color: #ff0000;
$aermec-color: $primary-color;
$luve-color: #003B75;
$uniflair-color: #009E4D;
$thermofin-color: #001E4C;
$kelvion-color: #d6c600;
$hisense-color: #009999;
$primary-gradient-color: transparentize($primary-color, .3);
$secondary-gradient-color: transparentize($secondary-color, .3);

$text-color: #1E1E1E;
$link-color: $primary-color;
$heading-color: $primary-color;
$placeholder-color: $gray-700;
$backdrop-color: transparentize($primary-color, .3);
$shadow: 0 0 10px $backdrop-color;

// UI Colors
$ui-colors: (
	default: $blue,
	primary: $primary-color,
	secondary: $secondary-color,
	success: $success,
	danger: $error,
	warning: $warning,
	info: $info
);

// Heading font size variables
$head-line-height: 1.3;
$head1-font-size: 3rem;
$head2-font-size: 2rem;
$head3-font-size: 1.5rem;
$head4-font-size: 1.25rem;
$head5-font-size: 1.1rem;
$head6-font-size: 1rem;
$head-small-color: $silver;
$head-color: $secondary-color;
$head-font-weight: 700;

// Select and Inputs
$input-border-focus: $primary-color;
$input-border-color: $white;
$input-radius: 4px;
$input-height: 45px;
$input-padding-vertical: 10px;
$input-padding-horizontal: 20px;
$input-background: $white;
$input-color: $text-color;
$input-placeholder-color: darken($silver2, 10%);
$input-font-weight: 500;
$input-background-disabled: $silver2;
$form-group-margin: .8rem;
$input-text-color: darken($silver2, 20%);
$input-text-size: $small-font-size;
$input-group-addon-bg: $silver2;

// select
$form-select-padding-right: 26px !default;
$form-select-indicator-color: $silver3 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
$form-select-bg-position: right $input-padding-vertical center !default;
$form-select-indicator-padding: $input-padding-horizontal * 3 !default; // Extra padding for background-image
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-feedback-icon-size: $input-height - $input-padding-vertical;

// Menu
$menu-background-color: $white;
$menu-font-color: $white;

// Alert
$alert-padding: 15px;
$alert-border-radius: 4px;

// Main page
$main-page-header-height: 50px;
$main-page-sidebar-width: 250px;
$main-page-background-color: #3F4657;
$main-page-text-color: #ffffff;

// Dropdown
$dropdown-background-color: #ffffff;
$dropdown-border-color: #d4d4d4;
$dropdown-border-radius: 3px;
$dropdown-text-color: $text-color;
$dropdown-item-padding-x: 1em;
$dropdown-item-padding-y: 0.85em;
$dropdown-item-font-size: $small-font-size;
$dropdown-item-text-color: $text-color;
$dropdown-item-background-color-hover: $silver;
$main-page-sidebar-background-color: #4A5367;

$btn-border-radius: 2rem;
$btn-border-radius-sm: 1.5rem;
$btn-border-radius-lg: 1rem;

$btn-font-size: $default-font-size;
$btn-font-size-sm: 0.7rem;
$btn-font-size-lg: 1.2rem;

$btn-padding-x: 2rem;
$btn-padding-x-sm: 1.5rem;
$btn-padding-x-lg: 2.5rem;

$btn-padding-y: 0.8rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-y-lg: 1rem;

$tabs-border-color: $input-border-color;
$tabs-color: $text-color;
$tabs-border-color-active: $primary-color;
$tabs-background-active: #fff;
$tabs-background: #fafafa;

$boxes-border-color: $input-border-color;
$boxes-header-background-color: #efefef;

// badge
$badge-font-size:                   .75em;
$badge-font-weight:                 400;
$badge-color:                       $white;
$badge-padding-y:                   .25em;
$badge-padding-x:                   .45em;
$badge-border-radius:               0;

// z-index
$zindex-header: 1300;
$zindex-modal: 1200;
$zindex-action: 1150;
$zindex-dropdown: 1100;
$zindex-sidebar: 1000;

//Grid variables
$grid-count: 12 !default;

$grid-class: col !default;
$gutter-width: 30px !default;

// grid
$grid-breakpoints: (
		xs: 0,
		sm: $sm-device,
		md: $md-device,
		lg: $lg-device,
		xl: $xl-device
) !default;

$container-max-widths: (
		sm: 540px,
		md: 720px,
		lg: 960px,
		xl: 1140px
) !default;

$grid-container: 'container';
$grid-container-fluid: 'container-fluid';
$row-name: 'row';
$grid-type: flex;//type of grid (either 'float' or 'flex' only)

// global
$enable-important-utilities:  true !default;

// transaction
$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
$transition-collapse-width:   width .35s ease !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
	("<", "%3c"),
	(">", "%3e"),
	("#", "%23"),
	("(", "%28"),
	(")", "%29"),
) !default;

// Path to Assets
$path--rel: "../images";
