.alert {
	padding: $alert-padding;
	margin-bottom: $base__line;
	border: 1px solid transparent;
	border-radius: $alert-border-radius;
	position: relative;

	h4,h3 {
		margin-top: 0;
		color: inherit;
	}

	.alert-link {
		font-weight: 500;
	}

	> p,
	> ul {
		margin-bottom: 0;
	}

	> p + p {
		margin-top: 5px;
	}
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert--dismissable, // The misspelled .alert-dismissable was deprecated in 3.2.0.
.alert--dismissible {
	padding-right: $alert-padding + 20;

	// Adjust close link position
	.close {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		padding: $alert-padding $alert-padding;
		color: inherit;
		border: none;
		background-color: transparent;
		font-size: $default-font-size;
	}
}

.alert--icons {
	padding-left: 4.5rem;

	&:before {
		font-size: $head1-font-size;
		font-weight: 400;
		position: absolute;
		width: 4rem;
		padding-left: $alert-padding;
		height: 100%;
		left: 0;
		top: 0;
		@include grid-flex-inline();
		@include grid-flex-align-items(center);
	}
}

.alert--fly {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	margin-bottom: 0;
	@include mq(md) {
		top: 0;
		bottom: inherit;
	}
}

.alert--success {
	@include alert-variant($success);
}

.alert--primary {
	@include alert-variant($primary-color);
}

.alert--info {
	@include alert-variant($primary-color);
}

.alert--warning {
	@include alert-variant($warning);
}

.alert--error {
	@include alert-variant($error);
}

.alert--danger {
	@include alert-variant($error);
}
