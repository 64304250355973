.breadcrumb {
	@include grid-flex();
	@include grid-flex-wrap(wrap);
	list-style: none;
	margin: 0;

	&__item {
		font-weight: bold;

		&.active {
			font-weight: normal;
		}
	}

	&__item + &__item {
		padding-left: 0.5rem;

		&:before {
			float: left;
			padding-right: 0.5rem;
			content: '/';
			color: $gray-400;
		}
	}
}
