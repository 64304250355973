.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: #000;

	@include m(icon) {
		fill: inherit;
		stroke: inherit;
		pointer-events: none;
		width: 20px;
		height: 20px;
	}

	@include m(text) {
		vertical-align: middle;
	}

	@include m(small) {
		width: 20px;
		height: 20px;
	}

	@include m(medium) {
		width: 35px;
		height: 35px;
	}

	@include m(large) {
		width: 55px;
		height: 55px;
	}

	@include m(xlarge) {
		width: 100px;
		height: 100px;
	}

	@include m(block) {
		display: block;
	}

	@include m(primary) {
		color: $primary-color;
		fill: $primary-color;
	}

	@include m(secondary) {
		color: $secondary-color;
		fill: $secondary-color;
	}

	@include m(silver) {
		color: $gray;
		fill: $gray;
	}

	@include m(white) {
		color: $white;
		fill: $white;
	}

	@include m(text) {
		width: $default-font-size;
		height: $default-font-size;
	}
}

/* stylelint-disable */
.icon-selin {
	font-size: calc(579 / 10) * 1rem;
	width: calc(579 / 579) * 1em;
}

/* stylelint-enable */
