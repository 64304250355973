// ==========================================================================
// Utilities
// Define functions and mixins at this partial.
// ==========================================================================

//Font Imports
// Utilities

$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
	(
		"align": (
			property: vertical-align,
			class: align,
			values: baseline top middle bottom text-bottom text-top
		),
		"position": (
			property: position,
			class: pos,
			values: (
				abs: absolute,
				rel: relative
			)
		),
		"float": (
			responsive: true,
			property: float,
			values: (
				start: left,
				end: right,
				none: none,
			)
		),
		"object-fit": (
			responsive: true,
			property: object-fit,
			values: (
				contain: contain,
				cover: cover,
				fill: fill,
				scale: scale-down,
				none: none,
			)
		),
		"opacity": (
			property: opacity,
			values: (
				0: 0,
				25: .25,
				50: .5,
				75: .75,
				100: 1,
			)
		),
		"display": (
			responsive: true,
			print: true,
			property: display,
			class: d,
			values: inline inline-block block grid table table-row table-cell flex inline-flex none
		),
		"width": (
			property: width,
			class: w,
			values: (
				25: 25%,
				50: 50%,
				75: 75%,
				100: 100%,
				auto: auto
			)
		),
		"abs-width": (
			property: width,
			class: wabs,
			values: (
				50: 50px,
				100: 100px,
				160: 160px,
				260: 260px,
				360: 360px,
				460: 460px,
				560: 560px,
				660: 660px,
				760: 760px,
			)
		),
		"max-width": (
			property: max-width,
			class: mw,
			values: (100: 100%)
		),
		"height": (
			property: height,
			class: h,
			values: (
				25: 25%,
				50: 50%,
				75: 75%,
				100: 100%,
				auto: auto
			)
		),
		"border": (
			property: border-width,
			class: border,
			values: (
				1: 1px,
				2: 2px,
				3: 3px
			)
		),
		"border-style": (
			property: border-style,
			class: border-style,
			values: (
				solid: solid,
				dotted: dotted
			)
		),
		"max-height": (
			property: max-height,
			class: mh,
			values: (100: 100%)
		),
		"max-abs-height": (
			property: max-height,
			class: mhabs,
			values: (
				50: 50px,
				100: 100px,
				360: 360px,
				460: 460px,
				560: 560px,
				660: 660px,
				760: 760px,
			)
		),
		"max-abs-width": (
			responsive: true,
			property: max-width,
			class: mwabs,
			values: (
				50: 50px,
				100: 100px,
				160: 160px,
				260: 260px,
				360: 360px,
				460: 460px,
				560: 560px,
				660: 660px,
				760: 760px,
			)
		),
		"flex": (
			responsive: true,
			property: flex,
			values: (fill: 1 1 auto)
		),
		"flex-direction": (
			responsive: true,
			property: flex-direction,
			class: flex,
			values: row column row-reverse column-reverse
		),
		"flex-grow": (
			responsive: true,
			property: flex-grow,
			class: flex,
			values: (
				grow-0: 0,
				grow-1: 1,
			)
		),
		"flex-shrink": (
			responsive: true,
			property: flex-shrink,
			class: flex,
			values: (
				shrink-0: 0,
				shrink-1: 1,
			)
		),
		"flex-wrap": (
			responsive: true,
			property: flex-wrap,
			class: flex,
			values: wrap nowrap wrap-reverse
		),
		"justify-content": (
			responsive: true,
			property: justify-content,
			values: (
				start: flex-start,
				end: flex-end,
				center: center,
				between: space-between,
				around: space-around,
				evenly: space-evenly,
			)
		),
		"align-items": (
			responsive: true,
			property: align-items,
			values: (
				start: flex-start,
				end: flex-end,
				center: center,
				baseline: baseline,
				stretch: stretch,
			)
		),
		"align-content": (
			responsive: true,
			property: align-content,
			values: (
				start: flex-start,
				end: flex-end,
				center: center,
				between: space-between,
				around: space-around,
				stretch: stretch,
			)
		),
		"align-self": (
			responsive: true,
			property: align-self,
			values: (
				auto: auto,
				start: flex-start,
				end: flex-end,
				center: center,
				baseline: baseline,
				stretch: stretch,
			)
		),
		"order": (
			responsive: true,
			property: order,
			values: (
				first: -1,
				0: 0,
				1: 1,
				2: 2,
				3: 3,
				4: 4,
				5: 5,
				last: 6,
			),
		),
		"text-opacity": (
			css-var: true,
			class: text-opacity,
			values: (
				25: .25,
				50: .5,
				75: .75,
				100: 1
			)
		),
		// scss-docs-end utils-color
		// scss-docs-start utils-bg-color
			"gray": (
				property: color,
				class: gray,
				values: $grays
			),
			"background-gray": (
				property: background-color,
				class: bg-gray,
				values: $grays
			),
			"border-color": (
				property: border-color,
				class: border-color,
				local-vars: (
					"bg-opacity": 1
				),
				values: map-merge(
					$ui-colors,
					(
						"transparent": transparent,
						"gray": $silver2
					)
				)
			),
			"background-color": (
					property: background-color,
					class: bg,
					local-vars: (
							"bg-opacity": 1
					),
					values: map-merge(
							$ui-colors,
							(
									"transparent": transparent
							)
					)
			),
			"bg-opacity": (
					css-var: true,
					class: bg-opacity,
					values: (
							10: .1,
							25: .25,
							50: .5,
							75: .75,
							100: 1
					)
			),
			"text-align": (
					responsive: true,
					property: text-align,
					class: text,
					values: (
							start: left,
							end: right,
							center: center,
					)
			),
			"text-decoration": (
					property: text-decoration,
					values: none underline line-through
			),
			"text-transform": (
					property: text-transform,
					class: text,
					values: lowercase uppercase capitalize
			),
	),
	$utilities
);

// Utility generator
// Used to generate utilities & print utilities
@mixin generate-utility($utility, $infix, $is-rfs-media-query: false) {
	$values: map-get($utility, values);

	// If the values are a list or string, convert it into a map
	@if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
		$values: zip($values, $values);
	}

	@each $key, $value in $values {
		$properties: map-get($utility, property);

		// Multiple properties are possible, for example with vertical or horizontal margins or paddings
		@if type-of($properties) == "string" {
			$properties: append((), $properties);
		}

		// Use custom class if present
		$property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
		$property-class: if($property-class == null, "", $property-class);

		// Use custom CSS variable name if present, otherwise default to `class`
		$css-variable-name: if(map-has-key($utility, css-variable-name), map-get($utility, css-variable-name), map-get($utility, class));

		// State params to generate pseudo-classes
		$state: if(map-has-key($utility, state), map-get($utility, state), ());

		$infix: if($property-class == "" and str-slice($infix, 1, 1) == "-", str-slice($infix, 2), $infix);

		// Don't prefix if value key is null (e.g. with shadow class)
		$property-class-modifier: if($key, if($property-class == "" and $infix == "", "", "-") + $key, "");

		@if map-get($utility, rfs) {
			// Inside the media query
			@if $is-rfs-media-query {
				$val: rfs-value($value);

				// Do not render anything if fluid and non fluid values are the same
				$value: if($val == rfs-fluid-value($value), null, $val);
			}
			@else {
				$value: rfs-fluid-value($value);
			}
		}

		$is-css-var: map-get($utility, css-var);
		$is-local-vars: map-get($utility, local-vars);
		$is-rtl: map-get($utility, rtl);

		@if $value != null {
			@if $is-rtl == false {
				/* rtl:begin:remove */
			}

			@if $is-css-var {
				.#{$property-class + $infix + $property-class-modifier} {
					--#{$prefix}#{$css-variable-name}: #{$value};
				}

				@each $pseudo in $state {
					.#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
						--#{$prefix}#{$css-variable-name}: #{$value};
					}
				}
			} @else {
				.#{$property-class + $infix + $property-class-modifier} {
					@each $property in $properties {
						@if $is-local-vars {
							@each $local-var, $variable in $is-local-vars {
								--#{$prefix}#{$local-var}: #{$variable};
							}
						}
						#{$property}: $value if($enable-important-utilities, !important, null);
					}
				}

				@each $pseudo in $state {
					.#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
						@each $property in $properties {
							@if $is-local-vars {
								@each $local-var, $variable in $is-local-vars {
									--#{$prefix}#{$local-var}: #{$variable};
								}
							}
							#{$property}: $value if($enable-important-utilities, !important, null);
						}
					}
				}
			}

			@if $is-rtl == false {
				/* rtl:end:remove */
			}
		}
	}
}


//Provide url in '$font-url--google' variable from '_config.scss' partial
@if variable-exists(font-url--google){
	@import url($font-url--google); // CSS font import from Google CDN
}

@if variable-exists(typefaces) {
	@each $file, $option in $typefaces {
		@font-face {
			font-family: map-get(map-get($typefaces, $file), family);
			font-style: map-get(map-get($typefaces, $file), style);
			font-weight: map-get(map-get($typefaces, $file), weight);
			src: url('../fonts/#{$file}.ttf') format('truetype'),
			url('../fonts/#{$file}.woff') format('woff'),
			url('../fonts/#{$file}.woff2') format('woff2');
		}
	}
}

// Functions

// Add pixel unit
// $value     - Value for adding px unit
// @returns   - number
@function addPxUnit($value){
	@if unitless($value) {
		$value: $value * 1px;
	}
	@return $value;
}

// Convert pixels to em
// $target    - Value to be converted into em
// $context   - Default: Base font size. Base font size is usually 16px depending on browser default font size
// @returns   - number
@function em($target, $context: $base__font-size){
	$target: addPxUnit($target);
	$context: addPxUnit($context);
	@return($target/$context) * 1em;
}

// Convert pixels to rem
// $target    - Value to be converted into rem
// $context   - Default: Base font size. Base font size is usually 16px depending on browser default font size
// @returns   - number
@function rem($target, $context: $base__font-size){
	$target: addPxUnit($target);
	$context: addPxUnit($context);
	@return calc($target / $context) * 1rem;
}

// Convert pixels to percentage
// $target    - Value to be converted into percentage
// $context   - Context on which target needs to be relative to for percentage conversion
// @returns   - number
@function pntg($target, $context){
	@return calc($target/$context) * 100%;
}

// Use color palette
// $palette   - Map required under $palettes map($palettes map is defined under _config.scss partial)
// $shade     - Key of provide $palette map
// base       - Default key of $shade under $palette map
// @returns   - map
@function palette($palette, $shade: base){
	@return map-get(map-get($palettes, $palette), $shade);
}

// Calculate if number is even or not
// $number    - Number to be verified
// @returns   - boolean
@function even($number){
	@if $number%2 == 0{
		@return true;
	}
	@else{
		@return false;
	}
}

// Mixins

// Map background color to elements
// $map    - Map containg key value pair for background color
// $theme  - Key of provided map from $map
// $color  - Value of provided map from $map
// @usage  - `.message {@include bg-colors($ui-colors);}`
@mixin bg-colors($map){
	@each $theme, $color in $map{
		&--#{$theme}{
			background: $color;
		}
	}
}

//BEM(Block Element Modifier) Naming Convention
// For Element
// $element  - Just the element name for the parent block (doesn't require the parent Block name)
// @usage    - `.Nav {@include e(item){...}}`
@mixin e($element){
	&#{'__' + $element}{
		@content;
	}
}

// For Modifier
// $modifier  - Just the modifier name for the parent block or element
// @usage     - `.Nav {@include e(item) {@include m(active) {...}}}`
@mixin m($modifier){
	&#{'--' + $modifier}{
		@content;
	}
}

// Media Queries
// $break  - RWD break point values, change them in _config.scss. Default: bootstrap grid
// @usage  - `.Nav {@include mq(sm) {...}}`
@mixin mqe($width){
	@media(min-width: $width){
		@content;
	}
}

// Media Queries
// $break  - RWD break point values, change them in _config.scss. Default: bootstrap grid
// @usage  - `.Nav {@include mq(sm) {...}}`
@mixin mq($break){
	@if $break == "xs" {//lowest breakpoint value
		@content;
	}
	@else if $break == "sm" {// phone
		@media(min-width: $sm-device){
			@content;
		}
	}
	@else if $break == "md" {// tablet
		@media(min-width: $md-device){
			@content;
		}
	}
	@else if $break == "lg" {// laptop and desktop
		@media(min-width: $lg-device){
			@content;
		}
	}
	@else if $break == "xl" {// High res desktop and TVs
		@media(min-width: $xl-device){
			@content;
		}
	}
	@else {
		@error "No value could be retrieved for '#{$break}'";
	}
}

// Media Queries
// $break  - RWD break point values, change them in _config.scss. Default: bootstrap grid
// @usage  - `.Nav {@include mq(sm) {...}}`
@mixin mqs($break){
	@if $break == "xs" {
		//nothing
	}
	@else if $break == "sm" {// phone
		@media(max-width: $sm-device - 1){
			@content;
		}
	}
	@else if $break == "md" {// tablet
		@media(max-width: $md-device - 1){
			@content;
		}
	}
	@else if $break == "lg" {// laptop and desktop
		@media(max-width: $lg-device - 1){
			@content;
		}
	}
	@else if $break == "xl" {// High res desktop and TVs
		@media(max-width: $xl-device - 1){
			@content;
		}
	}
	@else {
		@error "No value could be retrieved for '#{$break}'";
	}
}

// Hover mixin
// @usage  - `button {@include hover {...}}`
@mixin hover {
	&:hover,
	&:active,
	&:focus {
		//@extend %hover;
		@content;
	}
}

// Ellipsis mixin
// $width  - Value of width or max value of cutoff point in px
// @usage  - `.Text--info {@include ellipsis(120px) {...}}`
@mixin ellipsis($width){
	@extend %ellipsis;
	width : $width;
}

//Turn px in rem with px fallback
@mixin rem($size) {
	font-size: $size; //Fallback in px
	font-size: rem($size);
}

// Pseudo element mixin
// $el         - provide "before" or "after" string
// $el-width   - Width for the pseudo element in px
// $el-height  - Height for the pseudo element in px
// @usage      - `.dropdown {@include psuedo-el('before') {...}}`
@mixin psuedo-el($el, $el-width: null, $el-height: null){
	@if $el == 'before' or $el == 'after'{
		&:#{$el}{
			display: block;
			content: " ";
			position: absolute;
			width: $el-width;
			height: $el-height;
			@content;
		}
	}
	@else{
		@warn "#{$el} is not a valid pseudo-element";
	}
}

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin margin-classes( $breakpoints: $grid-breakpoints ){
	@each $name, $breakpoint in $breakpoints{
		//Generates breakpoint wise grid classes
		@include mq($name){
			@for $i from 0 through 10 {
				.top-#{$name}-up-#{$i+10} {
					top: -$i*10px;
				}
				.top-#{$name}-down-#{$i+10} {
					top: -$i*10px;
				}
				.left-#{$name}-up-#{$i+10} {
					left: $i*10px;
				}
				.left-#{$name}-down-#{$i+10} {
					left: -$i*10px;
				}
				.right-#{$name}-up-#{$i+10} {
					right: $i*10px;
				}
				.right-#{$name}-down-#{$i+10} {
					right: -$i*10px;
				}
				.mb-#{$name}-#{$i*5}{
					margin-bottom: $i*5px;
				}
				.mt-#{$name}-#{$i*5}{
					margin-top: $i*5px;
				}
				.ml-#{$name}-#{$i*5}{
					margin-left: $i*5px;
				}
				.mr-#{$name}-#{$i*5}{
					margin-right: $i*5px;
				}
				.m-#{$name}-#{$i*5}{
					margin: $i*5px;
				}
				.mx-#{$name}-#{$i*5}{
					margin-left: $i*5px !important;
					margin-right: $i*5px !important;
				}
				.my-#{$name}-#{$i*5} {
					margin-top: $i*5px !important;
					margin-bottom: $i*5px !important;
				}
				.pb-#{$name}-#{$i*5}{
					padding-bottom: $i*5px;
				}
				.pt-#{$name}-#{$i*5}{
					padding-top: $i*5px;
				}
				.pl-#{$name}-#{$i*5}{
					padding-left: $i*5px;
				}
				.pr-#{$name}-#{$i*5}{
					padding-right: $i*5px;
				}
				.px-#{$name}-#{$i*5}{
					padding-right: $i*5px !important;
					padding-left: $i*5px !important;
				}
				.py-#{$name}-#{$i*5}{
					padding-top: $i*5px !important;
					padding-bottom: $i*5px !important;
				}
				.p-#{$name}-#{$i*5}{
					padding: $i*5px !important;
				}
				.min-height-#{$name}-#{$i*50}{
					min-height: $i*50px;
				}
			}
			.mx-#{$name}-auto {
				margin: 0 auto;
			}
			.ml-#{$name}-auto {
				margin-left: auto;
			}
			.mr-#{$name}-auto {
				margin-right: auto;
			}
			.d-#{$name}-none {
				display: none!important;
			}
			.pull-right-#{$name} {
				float: right !important;
			}
			.pull-left-#{$name} {
				float: left !important;
			}
			.text-left-#{$name} {
				text-align: left !important;
			}
			.text-right-#{$name} {
				text-align: right !important;
			}
			.btn-#{$name}--block {
				display: block !important;
				text-align: center;
			}
		}
		@include mqs($name){
			.btn-#{$name}s--block {
				display: block !important;
				text-align: center;
			}
			.d-#{$name}-down-none {
				display: none!important;
			}
			.container-#{$name}-down-fullwidth {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}

// Animations
@mixin animation($animation) {
	-webkit-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}
@mixin animation-name($name) {
	-webkit-animation-name: $name;
	animation-name: $name;
}
@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
}
@mixin animation-timing-function($timing) {
	-webkit-animation-timing-function: $timing;
	animation-timing-function: $timing;
}
@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
	animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
	animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
	animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
	-webkit-animation-fill-mode: $fill-mode;
	animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms@mixin
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
	box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($box-sizing) {
	-webkit-box-sizing: $box-sizing;
	-moz-box-sizing: $box-sizing;
	box-sizing: $box-sizing;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $gutter-width) {
	-webkit-column-count: $column-count;
	-moz-column-count: $column-count;
	column-count: $column-count;
	-webkit-column-gap: $column-gap;
	-moz-column-gap: $column-gap;
	column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
	-webkit-hyphens: $mode;
	-moz-hyphens: $mode;
	-ms-hyphens: $mode; // IE10+
	-o-hyphens: $mode;
	hyphens: $mode;
	word-wrap: break-word;
}

// Placeholder text
@mixin placeholder($color: $placeholder-color) {
	// Firefox
	&::-moz-placeholder {
		color: $color;
		opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
	}
	&:-ms-input-placeholder { color: $color; } // Internet Explorer 10+
	&::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Transformations
@mixin transform($transform) {
	-webkit-transform: $transform;
	-ms-transform: $transform; // IE9 only
	-o-transform: $transform;
	transform: $transform;
}
@mixin scale($ratio) {
	-webkit-transform: scale($ratio);
	-ms-transform: scale($ratio); // IE9 only
	-o-transform: scale($ratio);
	transform: scale($ratio);
}
@mixin scale($ratioX, $ratioY) {
	-webkit-transform: scale($ratioX, $ratioY);
	-ms-transform: scale($ratioX, $ratioY); // IE9 only
	-o-transform: scale($ratioX, $ratioY);
	transform: scale($ratioX, $ratioY);
}
@mixin scaleX($ratio) {
	-webkit-transform: scaleX($ratio);
	-ms-transform: scaleX($ratio); // IE9 only
	-o-transform: scaleX($ratio);
	transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
	-webkit-transform: scaleY($ratio);
	-ms-transform: scaleY($ratio); // IE9 only
	-o-transform: scaleY($ratio);
	transform: scaleY($ratio);
}
@mixin skew($x, $y) {
	-webkit-transform: skewX($x, skewY($y));
	-ms-transform: skewX($x, skewY($y)); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
	-o-transform: skewX($x, skewY($y));
	transform: skewX($x, skewY($y));
}
@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
	-ms-transform: translate($x, $y); // IE9 only
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}
@mixin translateX($x) {
	-webkit-transform: translateX($x);
	-ms-transform: translateX($x); // IE9 only
	-o-transform: translateX($x);
	transform: translateX($x);
}
@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees); // IE9 only
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}
@mixin rotateX($degrees) {
	-webkit-transform: rotateX($degrees);
	-ms-transform: rotateX($degrees); // IE9 only
	-o-transform: rotateX($degrees);
	transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
	-webkit-transform: rotateY($degrees);
	-ms-transform: rotateY($degrees); // IE9 only
	-o-transform: rotateY($degrees);
	transform: rotateY($degrees);
}
@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	-moz-perspective: $perspective;
	perspective: $perspective;
}
@mixin perspective-origin($perspective) {
	-webkit-perspective-origin: $perspective;
	-moz-perspective-origin: $perspective;
	perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin; // IE9 only
	transform-origin: $origin;
}

@mixin gradient($direction, $list...) {
	background: -webkit-linear-gradient($direction, $list);
	background: -moz-linear-gradient($direction, $list);
	background: -o-linear-gradient($direction, $list);
	background: linear-gradient($direction, $list);
}

// Transitions

@mixin transition($transition...) {
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}
@mixin transition-property($transition-property...) {
	-webkit-transition-property: $transition-property;
	transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
	-webkit-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
	-webkit-transition-timing-function: $timing-function;
	transition-timing-function: $timing-function;
}
@mixin transition-transform($transition) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}


// User select
// For selecting text on the page

@mixin user-select($select) {
	-webkit-user-select: $select;
	-moz-user-select: $select;
	-ms-user-select: $select; // IE10+
	user-select: $select;
}

@mixin opacity($opacity) {
	$opacity-ie: ($opacity * 100);  // IE8 filter
	filter: "alpha(opacity=#{$opacity-ie})";
	opacity: $opacity;
}
