.slider {
	position: relative;

	&__inner {
		overflow: hidden;
		//margin: 0 -0.5rem;

		@include mqs(md) {
			//margin-left: -1.5rem;
			//margin-right: -1.5rem;
		}
	}

	&__items {
		@include grid-flex();
		margin: 0;
		font-size: 0;
		overflow-x: auto;
		@include transition(transform .3s linear);

		@include mq(lg) {
			overflow-x: visible;
			padding: 0;
		}

		@include mqs(md) {
			padding-left: 1rem;
		}
	}

	&__item {
		display: block;
		float: left;
		width: 37%;
		min-width: 19rem;
		max-width: 24rem;
		white-space: normal;
		flex-shrink: 0;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	@include mq(md) {
		.slider__item {
			max-width: none;
		}
	}

	&--items-8x {
		@include mq(xs) {
			.slider__item {
				width: 40%;
			}
		}
		@include mq(sm) {
			.slider__item {
				width: 28.571428%;
			}
		}
		@include mq(md) {
			.slider__item {
				width: 28.571428%;
			}
		}
		@include mq(lg) {
			.slider__item {
				width: 16.66667%;
			}
		}
		@include mq(xl) {
			.slider__item {
				width: 12.5%;
			}
		}
	}

	&--items-6x {
		@include mq(xs) {
			.slider__item {
				width: 40%;
			}
		}
		@include mq(sm) {
			.slider__item {
				width: 28.571428%;
			}
		}
		@include mq(md) {
			.slider__item {
				width: 25%;
			}
		}
		@include mq(lg) {
			.slider__item {
				width: 20%;
			}
		}
		@include mq(xl) {
			.slider__item {
				width: 16.66667%;
			}
		}
	}

	&--items-3x {
		@include mq(xs) {
			.slider__item {
				width: 40%;
			}
		}
		@include mq(sm) {
			.slider__item {
				width: 28.571428%;
			}
		}
		@include mq(md) {
			.slider__item {
				width: 33.33333%;
			}
		}
	}

	&--items-2x {
		@include mq(xs) {
			.slider__item {
				width: 40%;
			}
		}
		@include mq(sm) {
			.slider__item {
				width: 28.571428%;
			}
		}
		@include mq(md) {
			.slider__item {
				width: 50%;
			}
		}
	}
}

.slider {
	&__items {
		margin-bottom: 3rem;
	}

	&__footer {
		@include grid-flex-align-items(center);

		@include mqs(md) {
			.slider__pagination, .slider__arrows {
				display: none;
			}
		}
	}
}

.slider-navigation {
	@include grid-flex();
	list-style: none;
	margin: 0;

	li {
		a,button {
			font-size: 0;
			background-color: #C7C7C7;
			display: block;
			height: 2px;
			width: 40px;
			border: 0;
			cursor: pointer !important;
		}

		&.active {
			a,button {
				background-color: $primary-color;

				.theme--mitsubishi & {
					background-color: $mitsubishi-color;
				}
				.theme--luve & {
					background-color: $luve-color;
				}
				.theme--hisense & {
					background-color: $hisense-color;
				}
				.theme--kelvion & {
					background-color: $kelvion-color;
				}
				.theme--thermofin & {
					background-color: $thermofin-color;
				}
				.theme--uniflair & {
					background-color: $uniflair-color;
				}
			}
		}
	}

	li + li {
		margin-left: 10px;
	}
}

.main-slider {
	height: calc(100vh);
	min-height: 720px;
	margin-top: -62px;
	&__inner {
		height: 100%;
		margin: 0;

		@include mqs(md) {
			margin-left: 0;
			margin-right: 0;
		}
	}
	&__items {
		overflow-x: initial;
		height: 100%;
		padding-left: 0 !important;
	}
	&__item {
		width: 100%;
		height: 100%;
		max-width: none;
		padding-left: 0;
		padding-right: 0;
		&__inner {
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			@include grid-flex();
			@include grid-flex-align-items(center);

			position: relative;
			&::after {
				position: absolute;
				z-index: 1;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				content: "";
				pointer-events: none;
				background-color: rgba(0, 0, 0, .2);
			}
		}
		&__content {
			color: $white;
			margin-bottom: 4rem;
			z-index: 2;
			position: relative;

			h1,h2,h3,h4,h5 {
				color: $white;
				margin-bottom: 2rem;
			}
		}
		&__action {
			z-index: 2;
			position: relative;

			a {
				margin-bottom: 1rem;
			}

			@include mq(md) {
				a + a {
					margin-left: 1rem;
				}
			}
		}
	}
	&__control {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;

		&__top {
			margin-bottom: 2rem;
			.container {
				@include grid-flex();
				@include grid-flex-align-items(center);
				.main-slider__pagination {
					width: 33.3333%;
				}
				.main-slider__arrows {
					text-align: center;
					width: 33.3333%;
				}
			}
		}

		&__bottom {
			box-shadow: 0 15px 47px rgba(0, 0, 0, 0.06);
			opacity: 0.8;
			background-color: rgba(242, 248, 252, 1);
			padding: 2rem 0;
			display: none;

			@include mq(md) {
				display: block;
			}
		}
	}

	&__bar {
		&__item {
			text-align: center;

			&__title {
				font-size: $head3-font-size;
				color: $secondary-color;
				font-weight: 500;
				margin-bottom: .5rem;

				.theme--mitsubishi & {
					color: $mitsubishi-color;
				}
				.theme--luve & {
					color: $luve-color;
				}
				.theme--hisense & {
					color: $hisense-color;
				}
				.theme--kelvion & {
					color: $kelvion-color;
				}
				.theme--thermofin & {
					color: $thermofin-color;
				}
				.theme--uniflair & {
					color: $uniflair-color;
				}
			}
			&__desc {
				color: #5B5B5B;
			}
		}
	}

	.btn {
		@include box-shadow(0 4px 7px 0 rgba(0,0,0,4%));
	}
}
