.header {
	color: $text-color;
	z-index: $zindex-header;
	background-color: $white;
	position: relative;
	//top: 0;
	//left: 0;
	//right: 0;

	&__inner {
		@include grid-flex();

		@include mqs(lg) {
			@include grid-flex-direction(column);
		}
	}

	&__logo {
		padding: 1rem 0;
		margin-right: 2rem;
		@include grid-flex();
		@include grid-flex-align-items(center);

		a#logo {
			@include grid-flex();
			@include grid-flex-align-items(center);
			color: $white;

			img {
				height: 30px;

				&.additional-logo {
					border-left: 1px solid $silver2;
					margin-left: 0.5rem;
					padding-left: 0.5rem;
				}
			}
		}

		@include mqs(lg) {
			@include grid-flex();
			@include grid-flex-justify-content(space-between);
			margin-right: 0;
		}
	}

	&__menu {
		@include grid-flex();
		@include flex(1);
		@include grid-flex-align-items(center);
	}

	&__contact {
		@include grid-flex();
		@include grid-flex-align-items(center);

		@include mqs(lg) {
			width: 100%;
			margin-bottom: 1rem;
		}
	}
}

.main-menu-opener {
	color: $secondary-color;
	stroke: $secondary-color;
	fill: $secondary-color;
	@include grid-flex();
	@include grid-flex-align-items(center);
}

/**
 * Footer
 */
.footer {
	background-color: $text-color;
	color: $white;
	background-position: right bottom;
	background-repeat: no-repeat;
	background-image: url("../images/logo-o.svg");
	background-size: 50% 100%;

	&__inner {
		padding: 2rem 0;

		@include mq(md) {
			padding: 5rem 0;
		}
	}

	&__navigation {
		.footer__inner {
			border-top: 1px solid transparentize(#E2E2E2, .9);
			border-bottom: 1px solid transparentize(#E2E2E2, .9);
		}
	}

	&__copyright {
		@include mqs(md) {
			ul {
				@include grid-flex-direction(column-reverse);
			}
		}
	}

	.footer-text-grey {
		color: #B2B2B2;
	}

	.footer-heading {
		color: $primary-color;
		font-size: $base__font-size + .2;
		font-weight: bold;
		margin-bottom: 45px;

		&.hidden {
			visibility: hidden;
		}
	}

	.footer-references {
		@include grid-flex();
		@include grid-flex-wrap(wrap);
		list-style: none;

		li {
			width: 50%;
			flex: 0 0 50%;
			@include mq(md) {
				width: 25%;
				flex: 0 0 25%;
			}
			margin-bottom: 1rem;
			a {
				display: inline-block;
				img {
					filter: grayscale(1);
					height: 30px;
				}

				&:hover {
					img {
						filter: none;
					}
				}
			}
		}
	}

	.footer-socials {
		@include grid-flex();
		list-style: none;

		li + li {
			margin-left: 2rem;
		}
	}

	a {
		color: $white;
	}
}

.menu-list {
	list-style: none;

	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 2rem;

		&::before {
			font-size: $small-font-size;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-left: 5px solid $primary-color; /* změňte barvu podle potřeby */
			content: '';
			position: absolute;
			left: 2px;
			top: 5px;
		}
	}
}

.nav-menu {
	text-align: left;

	@include mq(lg) {
		display: block !important;
	}

	@include mqs(lg) {
		width: 100%;
	}

	> ul {
		@include grid-flex-inline();
		gap: 1rem;
		margin-bottom: 0;
		list-style: none;

		@include mqs(md) {
			@include grid-flex-direction(column);
			gap: 0;
			width: 100%;
			padding-bottom: 2rem;
		}

		li.nav-menu__item {
			&--link {
				> a {
					display: block;
					padding: 1.5rem 0;
					font-size: 1rem;
					color: $text-color;

					@include mq(md) {
						padding: 1.5rem 1.5rem;
					}
				}
			}

			&--icon {
				> a {
					display: block;
					padding: 1.5rem .5rem;
					color: $text-color;
				}
			}

			&--button {
				@include grid-flex();
				@include grid-flex-align-items(center);
			}

			&--submenu-rel {
				position: relative;
			}

			&--submenu {
				&:hover, &.active {
					@include mq(md) {
						.nav-menu-submenu {
							opacity: 1;
							visibility: visible;
						}
					}
					@include mqs(md) {
						.nav-menu-submenu {
							display: block;
						}
					}

					>a {
						background-color: $white;
					}

					&:after {
						top: 35px;
						transform: rotate(-135deg);
					}
				}

				&:after {
					content: " ";
					position: absolute;
					top: 30px;
					right: 5px;
					margin-top: -0.25em;
					width: 6px;
					height: 6px;
					border-width: 1px;
					border-style: solid;
					border-color: transparent $white $white transparent;
					transform: rotate(45deg);
				}
			}
		}
	}
}

.nav-menu-submenu {
	z-index: $zindex-sidebar;
	position: absolute;
	transition: 0.5s;
	list-style: none;
	background-color: $white;
	@include box-shadow(0 15px 47px 0 rgba(0,0,0,4%));

	@include mq(md) {
		opacity: 0;
		visibility: hidden;
	}
	@include mqs(md) {
		position: relative;
		display: none;
	}

	> li {
		border-top: 1px solid $gray-400;

		@include mq(md) {
			width: 300px;
		}

		> a {
			display: block;
			padding: 1rem 1.5rem;
			&:hover {
				background-color: $secondary-color;
			}
		}
	}
}

.submenu-wide {
	left: 0;
	right: 0;
	width: 100%;
	background-color: $white;

	&__heading {
		color: $secondary-color;
		font-weight: bold;
		font-size: $head4-font-size;
		margin-bottom: 1rem;
		display: block;
	}

	&__inner {
		padding: 1.5rem 0;
		@include mq(md) {
			padding: 1.5rem;
		}
	}

	&--image {
		@include grid-flex();
	}
}

.submenu-wide {
	.menu-list {
		li {
			a {
				color: $text-color;
			}
		}
	}
}

.menu-inline-list {
	width: 100%;
	list-style: none;
	@include grid-flex();
	@include grid-flex-direction(column);

	@include mq(md) {
		@include grid-flex-direction(row);
		@include grid-flex-justify-content(space-between);
		gap: 2rem;
	}

	li {
		margin-bottom: 2rem;

		@include mq(md) {
			margin-bottom: 0;
		}

		a {
			text-align: center;
			display: block;
			color: $text-color;
			font-weight: bold;

			img {
				margin-bottom: 1rem;
			}
		}
	}
}
