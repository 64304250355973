// ==========================================================================
// States
// A state is something that augments and overrides all other styles.
// Example:
// 1. Hamburger menu that is displayed only on mobile devices and hidden on desktop. Simply put,
// the state of the nav menu is to be hidden or visible.
// 2. Dropdown menu with an active opened state and inactive closed state.
// 3. States are used as object classes for existing elements.
// ==========================================================================

// Hidden for mobile devices
.is-collapsed-mobile {
	@include mq(sm){
		visibility: collapse;
		padding: 0;
		height: 0;
		margin: 0;
		line-height: 0;
	}
}

// Hidden for desktops
.is-displayed-mobile {
	@include mq(sm){
		display: block;
		@at-root (with:media){
			.is-hidden-mobile{
				display: none;
			}
		}
	}
	@include mq(md){
		display: none;
	}
}

.xs-hidden {
	@include mqs(sm) {
		display: none;
	}
}

.md-hidden {
	@include mqs(md) {
		display: none;
	}
}

.lg-hidden {
  @include mqs(lg) {
    display: none;
  }
}

.lg-visible {
  @include mq(lg) {
    display: none;
  }
}

.xs-visible {
	@include mq(sm) {
		display: none;
	}
}

.vertical-middle {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.position-md {
	@include mq(md) {
		position: absolute;
	}
}

.collapse {
	display: none;

	&.in      { display: block; }
	&.show    { display: block; }
	//tr&.in    { display: table-row; }
	//tbody&.in { display: table-row-group; }
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	@include transition-property("height, visibility");
	@include transition-duration(.35s);
	@include transition-timing-function(ease);
}

.fade {
	@include transition($transition-fade);

	&:not(.show) {
		opacity: 0;
	}
}

// scss-docs-start collapse-classes
.collapse {
	&:not(.show) {
		display: none;
	}
}

.collapsing {
	height: 0;
	overflow: hidden;
	@include transition($transition-collapse);

	&.collapse-horizontal {
		width: 0;
		height: auto;
		@include transition($transition-collapse-width);
	}
}

.im-css-hover {
	&__item {
		display: none;
	}

	&:hover {
		.im-css-hover__item {
			display: inline-block;
		}
	}
}
