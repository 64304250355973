//
// Main
//
.btn {
	font-family: $font-family--primary;
	font-size: $default-font-size;
	display: inline-block;
	padding: 0.8rem 2rem;
	font-weight: 600;
	border: 1px solid palette(gray-shades, dark);
	color: $text-color;
	cursor: pointer;
	background: palette(gray-shades, dark);
	white-space: nowrap;
	border-radius: $btn-border-radius;
	//line-height: $base__line;
	@include user-select(none);
	@include transition(background-color 1s ease);

	&:disabled,.disabled {
		@include opacity(0.5);
		cursor: not-allowed;
	}
}

// Button modifiers
.btn--uppercase {
	text-transform: uppercase;
}
.btn--block {
	width: 100%;
	display: block;
	text-align: center;
}
.btn--icons {
	padding-left: 0;
	padding-right: 0;
	text-align: center;
}
.btn--rounded {
	border-radius: 50%;
	padding: $btn-padding-y $btn-padding-y;
}
.btn--link {
	background: transparent;
}

//
// Button styles
//
.btn--primary {
	@include button-variant($primary-color);
}
.btn--outline-primary {
	@include button-outline-variant($primary-color);
}
.btn--secondary {
	@include button-variant($secondary-color);
}
.btn--outline-secondary {
	@include button-outline-variant($secondary-color);
}
.btn--success {
	@include button-variant($success);
}
.btn--outline-success {
	@include button-outline-variant($success);
}
.btn--danger {
	@include button-variant($error);
}
.btn--outline-danger {
	@include button-outline-variant($error);
}
.btn--warning {
	@include button-variant($warning);
}
.btn--outline-warning {
	@include button-outline-variant($warning);
}
.btn--info {
	@include button-variant($info);
}
.btn--outline-info {
	@include button-outline-variant($info);
}
.btn--white {
	@include button-variant($white, $primary-color);
}
.btn--outline-white {
	@include button-outline-variant($white, $primary-color);
}
.btn--theme-primary {
	@include button-variant($primary-color);

	.theme--mitsubishi & {
		@include button-variant($mitsubishi-color);
	}

	.theme--luve & {
		@include button-variant($luve-color);
	}

	.theme--hisense & {
		@include button-variant($hisense-color);
	}

	.theme--kelvion & {
		@include button-variant($kelvion-color);
	}

	.theme--thermofin & {
		@include button-variant($thermofin-color);
	}

	.theme--uniflair & {
		@include button-variant($uniflair-color);
	}
}

//
// Button sizes
//
.btn--lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn--sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}
