.section {
	display: block;
	padding: 2rem 0;

	@include mq(md) {
		padding: 5rem 0;
	}

	&--p0 {
		padding: 0 !important;
	}

	&__header {
		text-align: center;
		margin-bottom: 5rem;
		color: #5B5B5B;

		h1,h2,h3,h4,h5 {
			color: $text-color;
		}

		&--no-mb {
			margin-bottom: 0;
		}
	}

	&__content {

	}

	&__footer {
		margin-top: 1.5rem;
		text-align: center;
	}
}

.section {
	&--primary {
		background-color: $primary-color;
		color: $white;

		h1,h2,h3,h4,h5 {
			color: $white;
		}
	}

	&--secondary {
		background-color: $secondary-color;

		h1,h2,h3,h4,h5 {
			color: $white;
		}
	}

	&--grey {
		background-color: $third-color;
	}
}

.section-contact {
	@include grid-flex();
	@include grid-flex-direction(column);

	@include mq(md) {
		@include grid-flex-direction(row);
		&__content, &__image {
			width: 50%;
		}
	}

	&__content {
		@include mq(md) {
			@include grid-flex();
			@include grid-flex-align-items(center);
			@include grid-flex-justify-content(flex-end);

			&__inner {
				float: right;
				@include make-container-half();
				padding-top: 5rem;
				padding-bottom: 3rem;
			}
		}

		&__inner {
			padding-left: 1rem;
			padding-right: 1rem;

			h1 {
				text-align: center;
				color: $text-color;
			}

			h2,h3,h4,h5,h6 {
				color: $secondary-color;
			}
		}
	}
}
