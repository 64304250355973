@mixin grid-articles-item-image($height) {
	.articles-grid__item__image {
		height: $height !important;
		min-height: auto;
		max-width: 100%;
		width: 100% !important;

		img, video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

@mixin image-absolute($height) {
	height: $height !important;
	min-height: auto;
	max-width: 100%;
	width: 100% !important;

	img, video {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.articles-grid {

	&__item {
		display: block;
		position: relative;

		&::after {
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: "";
			pointer-events: none;
			background-color: rgb(0 0 0 / 20%);
		}

		&__content {
			padding: 1rem 2rem;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 2;
			color: $white;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}

		&__image {
			overflow: hidden;
		}
	}

	@include mqs(md) {
		.articles-grid__item {
			margin-bottom: 2rem;
		}
	}

	@include mq(lg) {
		display: grid;
		grid-column-gap: 2rem;
		grid-row-gap: 2rem;

		&--1-2 {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);

			.articles-grid__item:nth-child(1) {
				grid-area: 1 / 1 / 3 / 2;
				@include grid-articles-item-image(36vw);
			}

			.articles-grid__item:nth-child(2) {
				grid-area: 1 / 2 / 2 / 3;
				@include grid-articles-item-image(18vw);
			}

			.articles-grid__item:nth-child(3) {
				grid-area: 2 / 2 / 3 / 3;
				@include grid-articles-item-image(18vw);
			}

			.articles-grid__item {
				@include grid-articles-item-image(18vw);
			}
		}

		&--2-1 {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);

			.articles-grid__item:nth-child(1) {
				grid-area: 1 / 1 / 2 / 2;
				@include grid-articles-item-image(36vw);
			}

			.articles-grid__item:nth-child(2) {
				grid-area: 2 / 1 / 3 / 2;
				@include grid-articles-item-image(18vw);
			}

			.articles-grid__item:nth-child(3) {
				grid-area: 1 / 2 / 3 / 3;
				@include grid-articles-item-image(18vw);
			}
		}

		&--1-1 {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: 1fr;

			.articles-grid__item:nth-child(1) {
				grid-area: 1 / 1 / 2 / 2;
				@include grid-articles-item-image(18vw);
			}

			.articles-grid__item:nth-child(2) {
				grid-area: 1 / 2 / 2 / 3;
				@include grid-articles-item-image(18vw);
			}
		}
	}
}

.articles-item-video {
	width: 100%;
	object-fit: cover;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
}

.articles-item-image {
	max-width: 100%;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
}

.article {
	margin-bottom: 2rem;

	&__name {
		margin-bottom: 2rem;
		text-align: center;

		h1,h2,h3,h4,h5 {
			color: $text-color;
			font-size: $head2-font-size;
		}

		&--background {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

			padding: 3rem 0;
			text-align: left;

			h1,h2,h3,h4,h5 {
				color: $white;
			}
		}
	}

	&__date {
		text-align: center;
		margin-bottom: 2rem;
	}

	&__content {
		@include mq(md) {
			padding: 3rem 6rem;
		}
		p {
			margin: 1rem 0;
		}
	}

	&__related {
		&__name {
			text-align: center;
		}
	}

	&__footer {
		text-align: center;
	}
}

.article__box {
	&--reverse {
		@include grid-flex-direction(row-reverse);

		&.article__box--with-image {
			.article__box__content {
				padding-right: 2rem;
				padding-left: inherit;
			}
		}
	}

	&--with-image {
		@include mq(md) {
			@include grid-flex();

			.article__box__image {
				width: 50%;
			}
			.article__box__content {
				width: 50%;
				padding-left: 2rem;
			}
		}
	}
}

.article-tips {
	@include grid-flex();
	@include grid-flex-wrap(wrap);
	gap: 1rem;
	&__tip {
		background-color: $third-color;
		color: $primary-color;
		font-weight: 600;
		text-align: center;
		padding: 1.5rem 1rem;
	}
}

.articles {
	&__item {
		display: block;
		height: 100%;

		&__inner {
			height: 100%;
			position: relative;
			color: $text-color;
			box-shadow: 0 12px 47px 0 rgba(0,0,0,4%);
		}

		&__date {
			position: absolute;
			left: 10px;
			top: 10px;
			z-index: 1;
			background-color: $secondary-color;
			color: $white;
			font-weight: bold;
			padding: 0.5rem 1rem;
			font-size: $default-font-size;
		}

		&__image {
			position: relative;
			height: 15vw;
			img {
				width: 100%;
				height: 100%;
				position: absolute;
			}
		}

		&__content {
			color: inherit;
			padding: 2.5rem 2rem;
			font-size: $default-font-size;
			overflow: hidden;

			h1,h2,h3,h4,h5 {
				color: inherit;
				font-size: $head4-font-size;
			}

			p {
				color: #5B5B5B;
				margin-bottom: 0;
			}
		}
	}
}

//.slider__item {
//	.articles {
//		&__item {
//			height: 100%;
//			&__inner {
//				height: 100%;
//			}
//		}
//	}
//}

.articles-wrapper {
	&__items {
		margin-bottom: 4rem;
	}
	&__item {
		margin-bottom: 2rem;
	}
	&__pagination {
		text-align: center;
	}
}
