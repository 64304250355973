.gallery {
  &__inner {
    @include grid-flex();
    @include grid-flex-wrap(wrap);
    overflow: hidden;
    margin-right: calc($gutter-width / -2);
    margin-left: calc($gutter-width / -2);

    @include mqs(lg) {
      @include grid-flex-wrap(nowrap);
      overflow-x: auto;
    }
  }

  &--4x {
    .gallery__item {
      flex: 0 0 25%;
      max-width: 25%;

      @include mqs(lg) {
        flex: 0 0 30%;
        max-width: 30%;
      }

      @include mqs(md) {
        flex: 0 0 45%;
        max-width: 45%;
      }

      @include mqs(sm) {
        flex: 0 0 80%;
        max-width: 80%;
      }
    }
  }

  &--2x {
    .gallery__item {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &--5x {
    .gallery__item {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  &--6x {
    .gallery__item {
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    }
  }

  &__item {
    margin-bottom: 2rem;
    padding-right: calc($gutter-width / 2);
    padding-left: calc($gutter-width / 2);
  }
}
